.statusBtn {
  margin-bottom: 30px;
}

.editBtn {
  svg {
    fill: #1890ff;
  }
  &:hover {
    cursor: pointer;
  }
}

.columnView {
  display: flex !important;
  padding-left: 30px;

  .certificate {
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    .number {
      margin-right: 15px;
      font-size: 20px;
    }
  }
}

.loginGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 150px;
  justify-content: space-around;
}

.yearSelect {
  width: 85px !important;
}
