.ant-modal-header {
  text-align: center;
}

.ant-modal-body {
  .activateVoucher {
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      margin-top: 20px;
    }
  }
}
