.fileUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 150px;
  height: 100%;
  justify-content: space-around;

  > span {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
