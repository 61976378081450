.createVoucher {
  .ant-form {
    margin-left: 50px;
  }

  .inputGroup,
  .dateGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 15px;

    .ant-input,
    .ant-select {
      width: 200px;
    }
    input,
    textarea,
    .ant-select,
    .ant-picker {
      margin-top: 6px;
    }
  }

  .dateGroup {
    .ant-picker-input {
      input {
        width: 160px;
      }
    }
  }
}
