@import "~antd/dist/antd.css";
// select:focus,
// input:focus,
// button:focus {
//     outline: none;
// }
.error {
  color: #ff4d4f;
}
#root {
  height: 100%;
  width: 100%;
}
