.findInterpreterPage {
  .ant-form {
    .submit-btn {
      display: flex;
      justify-content: center;
      margin: 30px auto;
    }
  }

  .inputGroup,
  .dateGroup,
  .languageGroup,
  .genderGroup,
  .selectGroup,
  .dialectGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 15px;
    margin-left: 15px;

    .ant-input,
    .ant-select .ant-select-selector {
      align-items: center;
      height: 38px;
    }

    .ant-select-selection-search > input {
      height: 38px !important;
    }

    input,
    textarea,
    .ant-select,
    .ant-picker {
      margin-top: 6px;
    }
  }

  .languageGroup {
    .ant-radio-group {
      display: flex;
      flex-direction: column;
    }
  }

  .genderGroup {
    .ant-typography {
      margin-bottom: 5px;
    }
  }

  @media screen and(min-width: 1140px) {
    .dialectGroup {
      margin-left: 230px;
    }
  }
}
