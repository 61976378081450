.bonusBalance {
  line-height: 32px;
}

.totalStats {
  text-align: center;
}

.table-striped-columns tr td:nth-child(2n) {
  background-color: #fafafa;
}
