.detailsLink {
  position: relative;
  top: 3px;
}

.history {
  background-color: white;
  max-height: 20rem;
  overflow: auto;
}
