.logo {
  height: 32px;
  margin: 16px;
  margin-top: 32px;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 32px;
    transition: 0.3s;
    &.smallLogo {
      height: 28px;
    }
  }
}
.ant-layout-sider-children {
  position: fixed;
}
.collapsed {
  .ant-layout-sider-children {
    width: 80px;
  }
}

.not-collapsed {
  .ant-layout-sider-children {
    width: 200px;
  }
}

.logout-btn {
  position: absolute !important;
  bottom: 50px;
  &.collapsed {
    width: 79px;
  }
}
