.ant-modal-header {
  text-align: center;
}

.ant-modal-body {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .inpLine {
      width: 90%;

      .ant-select {
        width: 100%;
      }
    }

    button {
      margin-top: 20px;
    }
  }
}