.linkGroup {
  display: flex;
  align-items: center;
  background: #f0f0f0;
  border-radius: 5rem;
  width: 100%;
  padding-left: 2.5rem;
  margin-bottom: 3rem;

  > span {
    margin-left: -20px;
    margin-right: 10px;
  }

  > p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 36px;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 4px 0;
  }

  button {
    height: 44px;
    border-radius: 5rem;
    margin-right: -7px;
    > span {
      width: 70px;
    }
  }
}

.footerBtnGroup {
  display: flex;
  justify-content: center;
  > button:first-child {
    margin-right: 15px;
  }
}
