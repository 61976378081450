.inputGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;

  .ant-input {
    max-width: 300px;
  }
  input,
  textarea,
  .ant-select,
  .ant-picker {
    margin-top: 6px;
  }
}

.switchGroup {
  margin-top: 15px;
  display: flex;
  span {
    margin-right: 15px;
  }
}

.sliderGroup {
  margin-top: 15px;
  text-align: left;

  .ant-slider {
    max-width: 300px;
  }
}

.workingLanguages {
  margin-top: 15px;
  display: flex;
  max-width: 300px;

  .removeBtn {
    position: absolute;
    left: 280px;
  }

  .languageType {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 10px;

    .ant-radio-group {
      margin-top: 6px;

      span.ant-radio + * {
        padding-right: 3px;
        padding-left: 4px;
      }
    }
  }

  .ant-select {
    margin-top: 15px;
  }

  .addLanguage {
    margin-left: 105px;
  }
}

.saveBtn {
  margin-top: 30px;
}

.wrap > div {
  margin-top: 15px;
  display: flex;
  max-width: 300px;
}

.cerificateWrapper,
.statusProofWrapper {
  display: flex;
  padding-right: 50px;

  > button {
    margin-left: 15px;
  }
}

.uploadBtn {
  margin-left: 30px;
}

.updloadStatusProofBtn {
  margin-top: 20px;
}
