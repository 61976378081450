.voucherPage {
  table {
    margin-top: 30px;

    thead th,
    td {
      text-align: center;
    }
  }
}
