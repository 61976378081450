.voucherDetails {
  min-width: 700px;

  .ant-modal-body {
    .spinner {
      width: 100%;
      margin: 0 auto;
    }
  }
  .ant-modal-header {
    text-align: center;
  }
  .companys {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    h5 {
      width: 100%;
      text-align: center;
    }
    .companysList {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 40px;

      .companyInfo {
        .detailsLink {
          top: 1px;
        }
      }
    }
  }
}
